import React from "react";
import { RichText } from "prismic-reactjs";
import { linkResolver } from "@utilities/link-resolver.js";
import get from "lodash/get";
import { graphql } from "gatsby";
import Layout from "@components/layout";

import "@scss/page.scss";

export const pageQuery = graphql`
  query interviewSingleQuery($uid: String) {
    allPrismicInterview(filter: { uid: { eq: $uid }, lang: { eq: "en-us" } }) {
      edges {
        node {
          data {
            title {
              text
              html
              raw
            }
            content {
              text
              html
              raw
            }
          }
          uid
        }
      }
    }
  }
`;

const MixLaInterview = (props) => {
  const data = props.data;
  const currentPage = get(data, "allPrismicInterview.edges[0].node");

  return (
    <Layout>
      <div className="general-page" id={currentPage.uid}>
        <section>
          {currentPage.data.title && <h2>{currentPage.data.title.text}</h2>}
          {currentPage.data.header_image && (
            <img src={currentPage.data.header_image.url} />
          )}
          {currentPage.data.content && (
            <div className="general-page__content">
              <RichText
                render={currentPage.data.content.raw}
                linkResolver={linkResolver}
              />
            </div>
          )}
        </section>
      </div>
    </Layout>
  );
};

// MixLaInterview.query = pageQuery;

export default MixLaInterview;
