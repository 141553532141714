module.exports = {
  linkResolver({ node, key, value }) {
    return (doc) => {
      if (
        doc.type === "bloglist" ||
        doc.type === "page" ||
        doc.type === "contact" ||
        doc.type === "press" ||
        doc.type === "events" ||
        doc.type === "press" ||
        doc.type === "mix_la"
      ) {
        return `/${doc.uid}/`;
      } else if (doc.type === "interview") {
        return `/mix-la/${doc.uid}/`;
      } else if (doc.type === "event") {
        return `/events/${doc.uid}/`;
      }

      return "/";
    };
  },
  getUrlFromLink(link) {
    const { type } = link;
    if (!type) {
      return;
    }
    switch (type) {
      case "page":
        return `/${link.uid}/`;
        break;
      case "interview":
        return `/mix-la/${link.uid}/`;
        break;
      case "event":
        return `/events/${link.uid}/`;
        break;
      case "web":
        return `${link.url}`;
        break;
      default:
        console.log("test");
    }
  },
};
